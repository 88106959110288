import React from "react";
import { FaTools } from "react-icons/fa";
import Card from "./Card";

interface DataNavbarProps {
    views: any;
    setSelectedView: any;
}

const DataNavbar: React.FC<DataNavbarProps> = (props) => {
    return (
        <nav className="navbar">
            <ul>
                <li>
                    <Card
                        title="Match Scouting Interface"
                        description="View"
                        icon={<FaTools />}
                        onClick={() =>
                            props.setSelectedView(
                                props.views.MATCH_SCOUTING_INTERFACE
                            )
                        }
                    />
                </li>
                <li>
                    <Card
                        title="Pit Scouting Interface"
                        description="View"
                        icon={<FaTools />}
                        onClick={() =>
                            props.setSelectedView(
                                props.views.PIT_SCOUTING_INTERFACE
                            )
                        }
                    />
                </li>
                <li>
                    <Card
                        title="Comparison"
                        description="View"
                        icon={<FaTools />}
                        onClick={() =>
                            props.setSelectedView(props.views.COMPARISON)
                        }
                    />
                </li>
            </ul>
        </nav>
    );
};

export default DataNavbar;
