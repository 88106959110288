import Popup from "../components/Popup";
import DataNavbar from "../components/DataNavbar";
import React, { useContext, useEffect, useState } from "react";
import "../styles/DataView.css";
import Card from "../components/Card";
import { FaPlaneArrival } from "react-icons/fa";
import { UserContext } from "../App";
import MatchSchema from "../components/MatchSchema";
import PitSchema from "../components/PitSchema";
import Interface from "../components/Interface";

const DataView = () => {
    const views = Object.freeze({
        EVENT_VIEWER: "EVENT_VIEWER",
        SELECTOR: "SELECTOR",
        MATCH_SCOUTING_INTERFACE: "MATCH_SCOUTING_INTERFACE",
        PIT_SCOUTING_INTERFACE: "PIT_SCOUTING_INTERFACE",
        COMPARISON: "COMPARISON",
    });

    const setShowSidebar = useContext(UserContext).setShowSidebar;

    useEffect(() => {
        fetch("/api/all_competitions")
            .then((response) => response.json())
            .then((data) => {
                setEvents(
                    data.all_competitions.map(
                        (competition: any) => competition.name
                    )
                );
            })
            .catch((error) => console.error(error));
    }, []);

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [events, setEvents] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<string>("");

    const handleEventViewer = (evt: string) => {
        setSelectedEvent(evt);
        setSelectedView(views.SELECTOR);

        fetch(`/proc/get_comp_data/${evt}`, {
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.match_data);
                setData(data);
                // setSelectedView(views.MATCH_SCOUTING);
            })
            .catch((error) => console.error(error));
    };

    useEffect(() => {}, []);
    const handlePitScoutingView = () => {};
    const handleMatchScoutingView = () => {};
    const handleMatchScoutingInterfacesView = () => {};
    const handleComparisonView = () => {};

    const [selectedView, setSelectedView] = useState<string>(
        views.EVENT_VIEWER
    );

    const goBack = (view: string) => {
        setSelectedView(view);
    };

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h1>Data</h1>
            </div>
            {selectedView === views.EVENT_VIEWER && (
                <div className="events">
                    {events.map((event: any) => (
                        <Card
                            key={event}
                            title={event}
                            icon={<FaPlaneArrival />}
                            description="View Event"
                            onClick={() => handleEventViewer(event)}
                        />
                    ))}
                </div>
            )}

            {selectedView === views.SELECTOR && (
                <div>
                    <h1>Current Event: {selectedEvent}</h1>
                    <DataNavbar
                        views={views}
                        setSelectedView={setSelectedView}
                    />
                    <button className="back-btn" onClick={() => goBack(views.EVENT_VIEWER)}>
                        Go Back
                    </button>
                </div>
            )}

            {selectedView !== views.EVENT_VIEWER &&
                selectedView !== views.SELECTOR &&
                    selectedView !== views.COMPARISON && (
                    <button
                        className="back-btn-alt"
                        onClick={() => {
                            goBack(views.SELECTOR);
                            setShowSidebar(true);
                        }}
                    >
                        Go Back
                    </button>
                )}

            {selectedView === views.MATCH_SCOUTING_INTERFACE && (
                <div>
                    <h1>Match Scouting Interface</h1>
                    <MatchSchema data={data} setShowSidebar={setShowSidebar} />
                </div>
            )}

            {selectedView === views.PIT_SCOUTING_INTERFACE && (
                <div>
                    <h1>Pit Scouting Interface</h1>
                    <PitSchema data={data} setShowSidebar={setShowSidebar} />
                </div>
            )}
            

            {selectedView === views.COMPARISON && (
                <div>
                    <Interface selectedEvent={selectedEvent} />
                    <button className="back-btn" onClick={() => goBack(views.SELECTOR)}>
                        Go Back
                    </button>
                </div>
            )}

            <Popup trigger={showPopup} setTrigger={setShowPopup}>
                <h3>Popup</h3>
                <p>This is a popup</p>
            </Popup>
        </div>
    );
};

export default DataView;
