// ADD A CURRENT FORM SETTER

import React, { useEffect, useState, useContext } from "react";
import FormBuilder from "../components/FormBuilder";
import { UserContext } from "../App";
import Popup from "../components/Popup.tsx";
import "../styles/styles.css";

const Forms = () => {
    const [formDict, setFormDict] = useState({
        // id: null,
        // name: null,
        // description: null,
        // editor_name: null,
        // editor_notes: null,
        // version: null,
        // last_edited: null,
        // form_data: null,
    });

    const updateDict = (key, value) => {
        setFormDict({ ...formDict, [key]: value });
    };

    const user = useContext(UserContext).user;

    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [formCreate, setFormCreate] = useState(false);
    const [formEdit, setFormEdit] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [showFormBuilder, setShowFormBuilder] = useState(false);

    const [formName, setFormName] = useState("");
    const [formDescription, setFormDescription] = useState("");
    const [editorNotes, setEditorNotes] = useState("");
    const editorName = user.name;

    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        fetchForms();
    }, []);

    const fetchForms = () => {
        fetch("/api/get_forms", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Forms fetched");
                console.log(data);
                setForms(data);
            })
            .catch((error) => console.error("Error fetching forms:", error));
    };

    const createNewForm = () => {
        console.log("Opening form builder");
        setFormCreate(true);
    };

    const confirmDeleteForm = (id) => {
        console.log(id);
        setSelectedForm(id);
        setConfirmDelete(true);
    };

    const handleDeleteForm = async (id) => {
        const confirmDeleteInput =
            document.getElementById("confirmDeleteInput").value;
        if (confirmDeleteInput !== forms.find((form) => form.id === id).name) {
            console.log("Name does not match");
            console.log(confirmDeleteInput);
            console.log(forms.find((form) => form.id === id).name);
            return;
        }

        fetch(`/api/delete_form/${id}`, {
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    fetchForms();
                }
            })
            .catch((error) => console.error("Error deleting form:", error));

        fetchForms();
        setConfirmDelete(false);
    };

    const handleEditForm = (id) => {
        setSelectedForm(id);
        setFormEdit(true);
        console.log("Editing Form");

        // setFormDict(forms.find((form) => form.id === id));
        console.log(selectedForm);
    };

    const startFormBuilder = () => {
        console.log("Opening form builder");
        
        if (formName === "" || formDescription === "") {
            setShowWarning(true);
        } else {
            setShowFormBuilder(true);
        }
    };

    return showFormBuilder ? (
        <FormBuilder onChange={() => updateDict()} setShowFormBuilder={setShowFormBuilder} user={user} formName={formName} formDescription={formDescription} editorName={editorName} editorNotes={editorNotes} />
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "82vh",
            }}
        >
            <div>
                <h1>Form Builder</h1>
                <button onClick={createNewForm}>Create New Form</button>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Editor Name</th>
                            <th>Editor Notes</th>
                            <th>Version #</th>
                            <th>Form ID</th>
                            <th>Last Edited</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {forms.map((form) => (
                            <tr key={form._id}>
                                <td>{form.name}</td>
                                <td>{form.description}</td>
                                <td>{form.editor_name}</td>
                                <td>{form.editor_notes}</td>
                                <td>{form.version}</td>
                                <td>{form._id}</td>
                                <td>{form.last_edited}</td>
                                <td>
                                    <button
                                        type="button"
                                        onClick={() =>
                                            confirmDeleteForm(form.id)
                                        }
                                    >
                                        Delete Form
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleEditForm(form.id)}
                                    >
                                        Edit Form
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <Popup trigger={formEdit} setTrigger={setFormEdit}>
                    <h1>Form Builder</h1>
                    <p>Form Number: {selectedForm}</p>
                    {selectedForm !== null &&
                        forms.find((form) => form.id === selectedForm) && (
                            <>
                                <p>
                                    Form Name:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).name
                                    }
                                </p>
                                <p>
                                    Form Description:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).description
                                    }
                                </p>
                                <p>
                                    Form Editor:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).editor_name
                                    }
                                </p>
                                <p>
                                    Form Notes:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).editor_notes
                                    }
                                </p>
                                <p>
                                    Form Version:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).version
                                    }
                                </p>
                                <p>
                                    Form Last Edited:{" "}
                                    {
                                        forms.find(
                                            (form) => form.id === selectedForm
                                        ).last_edited
                                    }
                                </p>
                            </>
                        )}
                </Popup>

                <Popup trigger={formCreate} setTrigger={setFormCreate}>
                    <h1>Form Builder</h1>
                    <h2>Form Name:</h2>
                    <input
                        type="text"
                        value={formName}
                        onChange={(e) => setFormName(e.target.value)}
                        required={true}
                    />

                    <h2>Description:</h2>
                    <input
                        type="text"
                        value={formDescription}
                        onChange={(e) => setFormDescription(e.target.value)}
                        required={true}
                    />

                    

                    <h2>Editor Notes:</h2>
                    <input
                        type="text"
                        value={editorNotes}
                        onChange={(e) => setEditorNotes(e.target.value)}
                        required={true}
                    />

                    <h2>Editor Name:</h2>
                    <p>{user.name}</p>

                    <div style={{paddingTop: 20}}>
                        <button onClick={startFormBuilder}>
                            Create Form
                        </button>
                    </div>
                    
                </Popup>

                <Popup trigger={confirmDelete} setTrigger={setConfirmDelete}>
                    <h1>Are you sure you want to delete?</h1>
                    <p>Enter the Name of the Form to Delete</p>
                    <input id="confirmDeleteInput" />
                    <button
                        className="delete-btn"
                        onClick={() => handleDeleteForm(selectedForm)}
                    >
                        Delete
                    </button>
                </Popup>

                <Popup trigger={showWarning} setTrigger={setShowWarning}>
                    <h2>You are missing required fields</h2>
                    <button onClick={() => setShowWarning(false)}>Close</button>
                </Popup>
            </div>
        </div>
    );
};

export default Forms;
