// TODO
// IMPLEMENT ABILITY TO EDIT AND DELETE COMPS W/O GOING TO MONGODB COMPASS

import React, { useState, useEffect } from "react";
import Popup from "../components/Popup.tsx";
import "../styles/Panel.css";

// FIX THIS FILE LATER ????
const Admin = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [showCompetitionModal, setShowCompetitionModal] = useState(false);
    const [showNewCompetitionModal, setShowNewCompetitionModal] =
        useState(false);
    const [message, setMessage] = useState("");

    const [competitionName, setCompetitionName] = useState("");
    const [allCompetitions, setAllCompetitions] = useState([]);
    const [newAirtableAPIKey, setNewAirtableAPIKey] = useState("");

    useEffect(() => {
        retrieveDataFromServer();
    }, []);

    const retrieveDataFromServer = () => {
        fetch("/api/competition")
            .then((response) => response.json())
            .then((data) => setCompetitionName(data.name))
            .catch((error) =>
                console.error("Error fetching competition:", error)
            );

        fetch("/api/get_users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => setUsers(data.users))
            .catch((error) => console.error("Error fetching users:", error));

        fetch(`/api/all_competitions`)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setAllCompetitions(data.all_competitions);
            })
            .catch((error) =>
                console.error("Error fetching all competitions:", error)
            );
    };

    const [newUser, setNewUser] = useState({
        name: "",
        username: "",
        osis: "",
        role: "student",
    });
    const [users, setUsers] = useState([]);

    const handleCompetitionNameChange = (event) => {
        setCompetitionName(event.target.value);
    };

    const handleCompetitionAPIKeyChange = (event) => {
        setNewAirtableAPIKey(event.target.value);
    };

    const handleNewUserChange = (field, value) => {
        setNewUser((prevUser) => ({ ...prevUser, [field]: value }));
    };

    // this creates a competition
    const handleSaveCompetitionName = () => {
        console.log("Saving competition name:", competitionName);
        fetch("/api/add_competition_name", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                competition_name: competitionName,
                airtable_api_key: newAirtableAPIKey,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setShowPopup(true);
                retrieveDataFromServer();
                setMessage(`Competition name updated to ${competitionName}!`);
            })
            .catch((error) => {
                console.error("Error saving competition name:", error);
                setMessage(
                    "Competition name failed to update. Please try again."
                );
                setShowPopup(true);
            });
    };

    // this selects from the ALREADY CREATED competitions
    const handleSetCompetitionName = (competitionName) => {
        fetch("/api/set_default_competition", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                competition_name: competitionName,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setShowPopup(true);
                retrieveDataFromServer();
                setMessage(`Competition name updated to ${competitionName}!`);
            })
            .catch((error) => {
                console.error("Error saving competition name:", error);
                setMessage(
                    "Competition name failed to update. Please try again."
                );
                setShowPopup(true);
            });

        retrieveDataFromServer();
    };

    const handleAddUser = () => {
        if (!newUser.name || !newUser.username || !newUser.osis) {
            setMessage("Please fill out all fields.");
            setShowPopup(true);
            return;
        }

        if (newUser.osis === "101") {
            setMessage(
                "This is a reserved code. Please use a different OSIS/Password."
            );
            setShowPopup(true);
            return;
        }

        fetch("/api/create_account", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: newUser.username + "@nycstudents.net",
                osis: newUser.osis,
                name: newUser.name,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.error) {
                    setMessage(data.error);
                } else {
                    setMessage(`User ${newUser.username} added successfully!`);
                }
                setNewUser({ name: "", username: "", osis: "" });
                setShowPopup(true);
                retrieveDataFromServer();
                setUsers((prevUsers) => [...prevUsers, newUser]);
            })
            .catch((error) => {
                console.error("Error adding user:", error);
                setMessage("User failed to add. Please try again.");
                setShowPopup(true);
            });
        // retrieveDataFromServer();
    };

    const handleDeleteUser = (userId) => {
        fetch(`/api/delete_user/${userId}`, {
            method: "POST",
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    setUsers((prevUsers) =>
                        prevUsers.filter((user) => user.id !== userId)
                    );
                }
            })
            .catch((error) => console.error("Error deleting user:", error));
    };

    const handleChangeRole = (userId) => (event) => {
        fetch(`/api/change_role/${userId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                role: event.target.value,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.success) {
                    setUsers((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === userId
                                ? { ...user, role: event.target.value }
                                : user
                        )
                    );
                }
            })
            .catch((error) => console.error("Error changing role:", error));

        retrieveDataFromServer();
    };

    return (
        <div>
            <h1>Admin Panel</h1>

            <div>
                <h2>Current Competition</h2>
                <span className="panel-label">{competitionName}</span>
            </div>

            <div>
                <h2>Select Event</h2>
                {/* <label className="panel-label">Select Competition: </label> */}
                <button onClick={() => setShowCompetitionModal(true)}>
                    Select Competition
                </button>
            </div>

            <div>
                <h2>Create New Competition</h2>
                <label className="panel-label">Create New Competition: </label>
                <button onClick={() => setShowNewCompetitionModal(true)}>
                    Create New Competition
                </button>
            </div>

            <h2>Add New User</h2>
            <div>
                <label className="panel-label">Name:</label>
                <input
                    className="input"
                    type="text"
                    id="name"
                    name="name"
                    value={newUser.name}
                    style={{ width: "200px" }}
                    onChange={(e) =>
                        handleNewUserChange("name", e.target.value)
                    }
                    required
                />
                <label className="panel-label" htmlFor="username">
                    Username:
                </label>
                <input
                    className="input"
                    type="text"
                    id="username"
                    name="username"
                    value={newUser.username}
                    style={{ width: "200px" }}
                    onChange={(e) =>
                        handleNewUserChange("username", e.target.value)
                    }
                    required
                />
                <label className="panel-label" htmlFor="osis">
                    OSIS:
                </label>
                <input
                    className="input"
                    type="text"
                    id="osis"
                    name="osis"
                    value={newUser.osis}
                    style={{ width: "200px" }}
                    onChange={(e) =>
                        handleNewUserChange("osis", e.target.value)
                    }
                    required
                />
                <label className="panel-label" htmlFor="role">
                    Role:
                </label>
                <select
                    className="input"
                    id="role"
                    name="role"
                    value={newUser.role}
                    style={{ width: "200px" }}
                    onChange={(e) =>
                        handleNewUserChange("role", e.target.value)
                    }
                    required
                >
                    <option value="admin">Admin</option>
                    <option value="scouter">Scouter</option>
                    <option value="student">Student</option>
                </select>
                <br />
                <button onClick={handleAddUser}>Add User</button>
            </div>

            <h2>Existing Users</h2>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Username</th>
                        <th>OSIS</th>
                        <th>Role</th>
                        <th>Delete User</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.name}</td>
                            <td>{user.username}</td>
                            <td>{user.osis}</td>
                            <td>
                                <select
                                    value={user.role}
                                    onChange={handleChangeRole(user.id)}
                                >
                                    <option value="admin">Admin</option>
                                    <option value="scouter">Scouter</option>
                                    <option value="student">Student</option>
                                </select>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    onClick={() => handleDeleteUser(user.id)}
                                >
                                    Delete User
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Alert Popup */}
            <Popup
                trigger={showPopup}
                setTrigger={setShowPopup}
                onClose={() => setMessage(null)}
            >
                <h2>{message}</h2>
            </Popup>

            <Popup
                trigger={showCompetitionModal}
                setTrigger={setShowCompetitionModal}
            >
                <h2>Choose Competition</h2>
                <div>
                    {allCompetitions.map((competition) => (
                        <>
                            <button
                                key={competition.id}
                                onClick={() =>
                                    handleSetCompetitionName(competition.name)
                                }
                            >
                                {competition.name}
                            </button>
                            <br />
                        </>
                    ))}
                </div>
            </Popup>

            <Popup
                trigger={showNewCompetitionModal}
                setTrigger={setShowNewCompetitionModal}
            >
                <h2>Create New Competition</h2>
                <div>
                    <label className="panel-label">
                        Enter Competition Name:
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="competition_name"
                        name="competition_name"
                        value={competitionName}
                        onChange={handleCompetitionNameChange}
                        style={{ width: "300px" }}
                        required
                    />
                    <label className="panel-label">
                        Enter Airtable API Key:
                    </label>
                    <input
                        className="input"
                        type="text"
                        id="airtable_api_key"
                        name="airtable_api_key"
                        value={newAirtableAPIKey}
                        onChange={handleCompetitionAPIKeyChange}
                        style={{ width: "300px" }}
                        required
                    />
                    <br />
                    <button onClick={handleSaveCompetitionName}>Save</button>
                </div>
            </Popup>
        </div>
    );
};

export default Admin;
