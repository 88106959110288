import React, { useEffect, useState } from "react";
import Popup from "../components/Popup.tsx";
import "../styles/styles.css";
import "../styles/Widget.css";
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaCheckCircle } from "react-icons/fa";
import { FiTrash } from "react-icons/fi";

// BUGS
// fix duplicate names in groups
// if they are already in the practice group

const Shifts = () => {
    const [message, setMessage] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [showUsersPopup, setShowUsersPopup] = useState(false);

    const [users, setUsers] = useState([]);
    const [widgets, setWidgets] = useState([]);

    // search
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);

    const handleSearchChange = (event) => {
        const { value } = event.target;
        setSearchTerm(value);
        // Filter users based on search term
        const filtered = users.filter(user =>
            user.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    useEffect(() => {
        retrieveDataFromServer();
    }, [])

    const retrieveDataFromServer = () => {
        fetch("/api/get_users")
            .then((response) => response.json())
            .then((data) => setUsers(data.users))
            .catch((error) => console.error("Error fetching users:", error));

        fetch("/api/get_groups")
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setWidgets(data);
            })
            .catch((error) => console.error("Error fetching groups:", error));
    }

    const handleOnDrag = (e, name) => {
        e.dataTransfer.setData("name", name);
    };

    const saveGroups = async () => {
        fetch("/api/set_groups", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ groups: widgets }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setMessage('Successfully saved groups!');
                setShowPopup(true);
            })
            .catch((error) => {
                console.error("Error saving competition name:", error);
                setMessage('Error while saving groups!');
                setShowPopup(true);
            });
    }

    const handleOnDragOver = (e) => {
        e.preventDefault();
    };

    const handleWidgetRemove = (indexToRemove, name) => {
        setWidgets((prevWidgets) =>
            prevWidgets.filter((_, index) => index !== indexToRemove)
        );
        setUsers([...users, { name: name }]); // Add back to available users
    };

    const handleClick = () => {
        setShowUsersPopup(true);
    }

    const handleSelect = (name) => {
        console.log(name);
        if (!widgets.includes(name)) {
            // Add to widgets if not already present
            setWidgets([...widgets, name]);
            // Remove from available users
            setUsers(users.filter(user => user.name !== name));
        }
    };

    const alphabetizeUsers = () =>{
        const usersAlphabetized = users.sort((a, b) => a.name.localeCompare(b.name));
        setFilteredUsers(usersAlphabetized);
    }

    return (
        <div>
            <h1>Shifts</h1>
            <h3>Group 2</h3>
            <div
                className="page" onClick = {alphabetizeUsers}
            >
                <div className="widgets">
                    {widgets.map((widget, index) => (
                        <div
                            key={index}
                            className="widget"
                            onClick={() => handleWidgetRemove(index, widget)}
                            onMouseEnter={() => console.log("Testing")}
                        >
                            {widget}
                            <FiTrash size={50} color={"red"} style={{ cursor: "pointer", position: "absolute" }} />
                        </div>
                    ))}
                </div>
                <IoIosAddCircleOutline size={100} color="white" onClick={handleClick} style={{padding: 20}} />
            </div>

            <button style={{ margin: 20 }} onClick={saveGroups}>Save Groups</button>

            <Popup trigger={showPopup} setTrigger={setShowPopup}>
                <h1>{message}</h1>
            </Popup>
            <Popup trigger={showUsersPopup} setTrigger={setShowUsersPopup}>
                <h1>Select People for Shift 2</h1>
                <input
                    type="text"
                    placeholder="Search by name"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ marginBottom: '10px', padding: '5px' }}
                />
                <div className="widgets">
                    {filteredUsers.map((user) => (
                        <div 
                            key={user.id}
                            className="widget"
                            onClick={() => handleSelect(user.name)}
                        >
                            <p style={{ userSelect: "none", color: "#232b2b"}}>
                                {user.name}
                            </p>
                            {widgets.includes(user.name) ? <FaCheckCircle /> : null}
                        </div>
                    ))}
                </div>
            </Popup>
        </div>
    );
};

export default Shifts;
