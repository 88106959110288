import { useEffect, useState } from "react";
import Graph from "./Graph";
import Popup from "./Popup";

interface InterfaceProps {
    selectedEvent: string;
}

const Interface: React.FC<InterfaceProps> = (props) => {
    const [teamNumber, setTeamNumber] = useState(0);
    const [comparedTeamNumber, setComparedTeamNumber] = useState(0);
    const [teams, setTeams] = useState<any>();
    const [showPopup, setShowPopup] = useState(false);

    const [showData, setShowData] = useState(false);
    const [data, setData] = useState<any>({
        autonSpeakerNotesScored: [],
        autonMissed: [],
        telopSpeakerNotesScored: [],
        telopAmpNotesScored: [],
        telopSpeakerNotesMissed: [],
        telopAmpNotesMissed: [],
        trap: [],
        techFouls: [],
        yellowCards: [],
        redCards: [],
    });

    useEffect(() => {
        fetchTeams();
    }, []);

    const fetchTeams = () => {
        fetch("/api/get_team_data", {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setTeams(data.team_data);
            })
            .catch((error) => console.error("Error fetching teams:", error));
    };

    const fetchGraphData = () => {
        fetch(`/proc/get_proc_data/${props.selectedEvent}/${teamNumber}`, {
            method: "GET",
        })
            .then((response) => {
                if (response.status === 404) {
                    setShowPopup(true);
                    throw new Error("Data not found");
                }
                return response.json();
            })
            .then((data) => {
                setData(data.data);
                console.log(data.data);
                setShowData(true);
            })
            .catch((error) => {
                console.error("Error fetching graph data:", error);
                setShowData(false);
            });
    };

    const getTeamNameFromNumber = (teamNumber: number) => {
        if (teams) {
            const team = teams.find(
                (team: any) => team.team_number === teamNumber
            );
            return team.nickname;
        }
        return "Team " + teamNumber;
    };

    // const data = [
    //     { autonSpeakerNotesScored: 0, matchNumber: 3 },
    //     { autonSpeakerNotesScored: 1, matchNumber: 5 },
    //     { autonSpeakerNotesScored: 2, matchNumber: 4 },
    //     { autonSpeakerNotesScored: 3, matchNumber: 3 },
    //     { autonSpeakerNotesScored: 4, matchNumber: 6 },
    //     { autonSpeakerNotesScored: 5, matchNumber: 7 },
    //     { autonSpeakerNotesScored: 6, matchNumber: 8 },
    //     { autonSpeakerNotesScored: 7, matchNumber: 9 },
    //     { autonSpeakerNotesScored: 8, matchNumber: 9 },
    // ];

    const graphs = [
        <div>
            <h1>Scoring Accuracy</h1>

            <h2>Auton Scoring</h2>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Graph
                    data={data.autonSpeakerNotesScored} // autonSpeakerNotesScored
                    icon={undefined}
                    title={"Auton Speaker Notes Consistency"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"autonSpeakerNotesScored"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
                <Graph
                    data={data} // autonMissed
                    icon={undefined}
                    title={"Auton Missed"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"autonMissed"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>

            <h2>Teleop Scoring</h2>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Graph
                    data={data.telopSpeakerNotesScored} // telopSpeakerNotesScored
                    icon={undefined}
                    title={"Teleop Speaker Notes Consistency"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"telopSpeakerNotesScored"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
                <Graph
                    data={data.telopAmpNotesScored} // telopAmpNotesScored
                    icon={undefined}
                    title={"Teleop Amp Notes Consistency"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"telopAmpNotesScored"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <Graph
                    data={data.telopSpeakerNotesMissed} // telopSpeakerNotesMissed
                    icon={undefined}
                    title={"Teleop Speaker Notes Missed"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"telopSpeakerNotesMissed"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
                <Graph
                    data={data.telopAmpNotesMissed}
                    icon={undefined}
                    title={"Teleop Amp Notes Missed"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"telopAmpNotesMissed"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>

            <h2>End Game</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Graph
                    data={data.trap}
                    icon={undefined}
                    title={"Trap"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"trap"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>

            <h2>Fouls</h2>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Graph
                    data={data.techFouls}
                    icon={undefined}
                    title={"Fouls"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"techFouls"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
                <Graph
                    data={data.techFouls}
                    icon={undefined}
                    title={"Tech Fouls"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"techFouls"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>

            <h2>Received Cards</h2>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Graph
                    data={data.yellowCards}
                    icon={undefined}
                    title={"Yellow Cards"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"yellowCards"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
                <Graph
                    data={data.redCards}
                    icon={undefined}
                    title={"Red Cards"}
                    description={""}
                    dataKeyX="matchNumber"
                    dataKeyY={"redCards"}
                    graphProps={{ height: 500, width: 500, paddingTop: 20 }}
                />
            </div>
        </div>,
    ];

    return (
        <div>
            <h1>Interface</h1>
            {!showData ? (
                <div>
                    <h2>Enter Team Number:</h2>
                    <input
                        type="number"
                        placeholder="Team Number"
                        onChange={(e) =>
                            setTeamNumber(parseInt(e.target.value))
                        }
                    />

                    <button
                        onClick={() => {
                            fetchGraphData();
                        }}
                    >
                        Show Graphs
                    </button>
                </div>
            ) : (
                <div>
                    <h1>Graph of {getTeamNameFromNumber(teamNumber)}</h1>
                    {graphs}
                </div>
            )}

            <Popup trigger={showPopup} setTrigger={setShowPopup}>
                <h2>Team data doesn't exist.</h2>
                <button onClick={() => setShowPopup(false)}>
                    Ok
                </button>
            </Popup>
        </div>
    );
};

export default Interface;
