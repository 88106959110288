import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Admin from "./screens/Admin";
import Analytics from "./screens/Analytics";
import Forms from "./screens/Forms";
import Login from "./screens/Login";
import Shifts from "./screens/Shifts";
import Unauthenticated from "./screens/Unauthenticated";
import Sidebar from "./components/Sidebar";
import DataView from "./screens/DataView.tsx";
import supabase from "./auth.ts";
import './App.css';


export const UserContext = createContext();

function App() {
    const [session, setSession] = useState(null);
    const [showSidebar, setShowSidebar] = useState(true);
    const [user, setUser] = useState({
        id: "",
        name: "",
        username: "",
        osis: "",
        email: "",
        role: "",
    });

    useEffect(() => {
        // Fetch competition data when the component mounts
        fetch("/api/competition")
            .then((response) => response.json())
            .catch((error) =>
                console.error("Error fetching competition:", error)
            );
    }, []);

    useEffect(() => {
        supabase.auth.getSession().then(async ({ data: { session } }) => {
            const userData = await session.user?.user_metadata;
            const user = {
                "id": userData.sub,
                "name": userData.name,
                "username": userData.username,
                "osis": userData.osis,
                "email": userData.email,
                "role": userData.role,
            }
            setUser(user);
            setSession(session);
        }).catch(console.error);
  
        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });
  
        return () => subscription.unsubscribe();
    }, [user]);

    const handleLogout = async () => {
        const { error } = await supabase.auth.signOut();
        console.log(error);
    };

    return session ? (
        <BrowserRouter>
            <div className="app">
                <UserContext.Provider value={{user, setShowSidebar}}>
                    {showSidebar && (
                        <div className="sidebar-container">
                            <Sidebar id='sidebar' className='sidebar' handleLogout={handleLogout} />
                        </div>
                    )}
                    <div id='content' className="content">
                        {user.role === "admin" ? (
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/analytics" element={<Analytics />} />
                                <Route path="/panel" element={<Admin />} />
                                <Route path="/forms" element={<Forms />} />
                                <Route path="/shifts" element={<Shifts />} />
                                <Route path="/data" element={<DataView />} />
                            </Routes>
                        ) : user.role === "scouter" ? (
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/analytics" element={<Analytics />} />
                                <Route path="/forms" element={<Forms />} />
                                <Route path="/shifts" element={<Shifts />} />
                                <Route path="/data" element={<DataView />} />
                            </Routes>
                        ) : (
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/analytics" element={<Analytics />} />
                                <Route path="/data" element={<DataView />} />
                            </Routes>
                        )}
                    </div>
                    {/* <Footer /> */}
                </UserContext.Provider>
            </div>
        </BrowserRouter>
    ) : (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <Login
                            setUser={setUser}
                            user={user}
                        />
                    }
                />
                <Route path="/analytics" element={<Unauthenticated />} />
                <Route path="/panel" element={<Unauthenticated />} />
                <Route path="/forms" element={<Unauthenticated />} />
                <Route path="/shifts" element={<Unauthenticated />} />
                <Route path="/data" element={<Unauthenticated />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
