import React, { useState } from "react";
import {
    Bar,
    BarChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import Card from "./Card";

interface GraphProps {
    data: any;
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick?: () => void;
    graphProps?: React.CSSProperties;
    dataKeyX: string;
    dataKeyY: string;
}

const Graph: React.FC<GraphProps> = ({data, icon, title, description, onClick, graphProps, dataKeyX, dataKeyY}) => {
    const getData = async () => {
        const response = await fetch("/proc/match");
        const data = await response.json();
        console.log(data);
    };

    // a value of 500px is good for the graph in the Card
    

    return (
        <div>
            <Card
                cardStyles={{width: 500}}
                icon={icon}
                title={title}
                description={description}
                childrenProps={graphProps}
                onClick={onClick}
            >
                <ResponsiveContainer width={"100%"} height={"100%"}> 
                    <BarChart width={100} height={100} data={data}>
                        <Tooltip />
                        <Bar dataKey={dataKeyY} fill="#8874d8" />
                        <XAxis label={dataKeyX} dataKey={dataKeyX} />
                        <YAxis dataKey={dataKeyY} />
                    </BarChart>
                </ResponsiveContainer>
            </Card>
        </div>
    );
};

export default Graph;
