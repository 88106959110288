import React, { Children } from 'react';
import '../styles/Card.css';

interface CardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
    onClick?: () => void;
    children?: React.ReactNode;
    childrenProps?: React.CSSProperties;
    cardStyles?: React.CSSProperties;
}

const Card: React.FC<CardProps> = ({ icon, title, description, onClick, children, childrenProps, cardStyles }) => {
    return (
        <div style={cardStyles} className="card" onClick={onClick}>
            <div className="card-icon">{icon}</div>
            <div className="card-title">{title}</div>
            <div className="card-description">{description}</div>
            <div style={childrenProps}>{children}</div>
        </div>
    );
};

export default Card;
